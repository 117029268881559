import { render, staticRenderFns } from "./add_addr.vue?vue&type=template&id=61721916&scoped=true"
import script from "./add_addr.vue?vue&type=script&lang=js"
export * from "./add_addr.vue?vue&type=script&lang=js"
import style0 from "./add_addr.vue?vue&type=style&index=0&id=61721916&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61721916",
  null
  
)

export default component.exports