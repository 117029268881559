<template>
  <div class="add_acc">
    <div>
      <el-form label-position="top" label-width="auto" @submit.native.prevent>
        <el-form-item :label="$t('main.text74')" class="is-required">
          <el-input
            v-model="form.payee_addr"
            :placeholder="$t('main.text75')"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="btm_btn btn_ok" v-if="form.payee_addr" @click="save_btn">
      {{ $t("main.text77") }}
    </div>
    <div class="btm_btn btn_no" v-else>{{ $t("main.text77") }}</div>
  </div>
</template>

<script>
import { payee_add, payee_edit } from "../../../api/api";
export default {
  data() {
    return {
      form: {
        edit_data: {},
      },
      is_edit: this.$route.query.is_edit,
    };
  },
  beforeMount() {
    if (this.is_edit) {
      sessionStorage.setItem("hichat_title", this.$t("main.text80"));
      let edit_data = JSON.parse(sessionStorage.getItem("payee_addr"));
      this.form.payee_addr = edit_data.payee_addr;
      this.form.tx_id = edit_data.tx_id;
      this.edit_data = edit_data;
    } else {
      sessionStorage.setItem("hichat_title", this.$t("main.text81"));
    }
  },
  mounted() {},
  methods: {
    setStor() {
      let obj = {
        payee_addr: this.form.payee_addr,
        tx_id: this.edit_data.tx_id || "",
      };
      sessionStorage.setItem("payee_addr", JSON.stringify(obj));
    },
    async save_btn() {
      if (!this.is_edit) {
        const res = await payee_add({
          payee_type: 2,
          payee_addr: this.form.payee_addr,
        });
        if (res.data.code !== 200) {
          return this.msg("error", res.data.msg);
        }
        this.msg("success", this.$t("main.text10"));
        this.$router.push({ path: "/set/set_addr" });
        // this.setStor();
      } else {
        const res = await payee_edit({
          tx_id: this.edit_data.tx_id,
          payee_type: 2,
          payee_addr: this.form.payee_addr,
        });
        if (res.data.code !== 200) {
          return this.msg("error", res.data.msg);
        }
        this.msg("success", this.$t("main.text10"));
        this.$router.go(-1);
        this.setStor();
      }
    },
  },
};
</script>

<style scoped>
.add_acc {
  box-sizing: border-box;
  padding: 1.25rem;
  position: relative;
}
.add_acc >>> .el-form-item__label {
  line-height: 17px;
  padding-bottom: 0.5rem;
}
.btm_btn {
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>

